export default {
  id: 'consultancy',
  name: 'Consultancy',
  path: '/consultancy',
  desc: 'Through the Consultancy process, we analyse your business requirements and provide solutions design and recommendations to meet your needs, that align to our Cloud First approach for hosting GSK applications and data.',
  graphic: 'on-premise_icon',
  breadcrumbParent: 'home',
  breadcrumbTitle: 'Consultancy',
  menuTitle: 'Consultancy',
  routeName: 'consultancy',
  services: [
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Enterprise Hosting Consultancy',
      desc: 'Request expert support with the management of your cloud solution from a certified partner. Get in touch with your cloud solution requirements online now.',
      graphic: 'cloud',
      id: 'cloudConsultancyOverview',
      menuTitle: 'Enterprise Hosting Consultancy',
      name: 'Enterprise Hosting Consultancy',
      path: '/consultancy/enterprise-hosting-consultancy/overview',
      routeName: 'cloudConsultancyOverview',
    },
    {
      breadcrumbParent: 'cloudConsultancyOverview',
      breadcrumbTitle: 'Create',
      id: 'cloudConsultancyForm',
      path: '/consultancy/enterprise-hosting-consultancy/form',
      routeName: 'cloudConsultancyForm',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'On-Premise Consultancy',
      desc: 'Through the On-Premise Consultancy process, we analyse your business requirements and provide solutions design recommendations to meet your needs. Our recommendations align to the Cloud First strategy, as we now have an On-Premise by Exception approach for hosting GSK applications and data.',
      graphic: 'on-premise_icon',
      id: 'onPremiseConsultancyOverview',
      menuTitle: 'On-Premise Consultancy',
      name: 'On-Premise Consultancy',
      path: '/consultancy/on-premise/overview',
      routeName: 'onPremiseConsultancyOverview',
    },
  ],
};
